<template>
  <v-container grid-list-xl>
    <v-layout
      row
      wrap
    >
      <v-flex
        lg12
        sm12
        xs12
      >
        <v-form
          ref="form"
          lazy-validation
          class="app-form"
          autocomplete="off"
          @submit.prevent="onSubmit"
        >
          <v-card class="app-card">
            <v-card-title class="app-card-title">
              <h4 class="text">
                <span>Perfil</span>
              </h4>
            </v-card-title>
            <v-card-text class="app-card-content">
              <v-row>
                <v-col
                  :lg="6"
                  :md="6"
                  :sm="6"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="user.name"
                    outlined
                    dense
                    label="Nome *"
                    :rules="[rules.required]"
                    disabled=""
                  />
                </v-col>
                <v-col
                  :lg="6"
                  :md="6"
                  :sm="6"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="user.email"
                    outlined
                    dense
                    label="Email *"
                    placeholder="Digite o email"
                    :rules="[rules.required, rules.email]"
                    disabled=""
                  />
                </v-col>
                <v-col
                  :lg="6"
                  :md="6"
                  :sm="6"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="form.password"
                    outlined
                    dense
                    label="Senha *"
                    placeholder="Digite a senha"
                    type="password"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  :lg="6"
                  :md="6"
                  :sm="6"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="form.passwordConfirmation"
                    outlined
                    dense
                    label="Confirmar Senha *"
                    placeholder="Digite a senha"
                    type="password"
                    :rules="[rules.required]"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions class="app-card-actions">
              <span class="app-form__warning">
                Atenção: Campos que possuem o
                <em>*</em> são obrigatórios
              </span>
              <v-spacer />
              <v-btn
                depressed
                outlined
                color="error"
                @click="$router.back()"
              >
                Voltar
              </v-btn>
              <v-btn
                depressed
                color="success"
                type="submit"
              >
                Salvar
              </v-btn>
            </v-card-actions>
            <section
              v-if="isLoading"
              class="loading"
            >
              <v-progress-circular
                indeterminate
                color="success"
              />
              <span>Enviando informações ...</span>
            </section>
          </v-card>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import GlobalStore from '@/store/modules/global';
  import AuthenticationService from '@/services/authentication.service';

  export default {
    name: 'Profile',
    data: () => ({
      form: {
        password: null,
        passwordConfirmation: null
      },
      rules: {
        required: value => !!value || 'Esse campo deve ser preenchido',
        email: value => {
          var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return re.test(value) || 'E-mail fora do padrão';
        }
      },
      isLoading: false
    }),
    computed: {
      user: () => GlobalStore.user || {},
      token: () => AuthenticationService.token || ''
    },
    methods: {
      onSubmit() {
        const isValid = this.$refs.form.validate();

        if (isValid) {
          this.isLoading = true;
          const { password, passwordConfirmation } = this.form;
          const data = { password, passwordConfirmation, token: this.token }
          AuthenticationService.resetPassword(
            data
          ).subscribe(
            response => {
              GlobalStore.setSnackBar({
                timeout: 10000,
                show: true,
                message: 'Sua senha foi alterado com sucesso!',
                type: 'success'
              })
              this.isLoading = false;
              this.$router.push({name: 'dashboard-home'})
            }, error => {
              this.isLoading = false;
            //throwError(error);
            }
          );
        }
      },
    }
  }
</script>
